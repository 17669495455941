<template>
  <div class="info-module-dialog" v-if="isShowModuleDialog">
    <div class="module-dialog">
      <div class="dialog-header">
        <i class="el-icon-close" @click="closeModuleDialog"></i>
        <h2 style="text-align: center">{{ $t("lang.messageDetail") }}</h2>
      </div>
      <div
        class="event-container"
        v-loading="loading"
        :element-loading-text="$t('lang.loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 250, 233, 0.8)"
      >
        <div class="event-item" @click.stop="initChangeTo(eventDetail)">
          <div
            :class="
              eventDetail.status || eventDetail.status === 0
                ? `event-status ${statusClass[eventDetail.status]}`
                : `event-status`
            "
          >
            {{ statusNameMap[eventDetail.status] }}
          </div>
          <div class="event-time">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-rili-mian"></use>
            </svg>
            <el-date-picker
              prefix-icon="el-icon-none"
              v-model="eventDetail.date"
              type="datetimerange"
              range-separator="—"
              :start-placeholder="$t('lang.formHolder[5]')"
              :end-placeholder="$t('lang.formHolder[6]')"
              align="right"
              format="yyyy-MM-dd HH:mm"
              :disabled="handleShowBtn('disableDate', eventDetail)"
              class="datetime"
            >
            </el-date-picker>
          </div>
          <div class="event-targets">
            <span
              class="event-postUser"
              style="margin-right: 1rem"
              v-show="eventDetail.status == 0 && !eventDetail.receiveUser.id"
              >{{ $t("lang.invites_pub") }}</span
            >
            <span class="event-postUser">
              <img
                :src="
                  eventDetail.postUser
                    ? eventDetail.postUser.avatar
                    : avatarDefault
                "
                class="postUser-avatar"
              />
              <span class="postUser-username"
                >{{ eventDetail.postUser ? eventDetail.postUser.username : "" }}
              </span>
            </span>
            <span
              class="event-action"
              v-show="eventDetail.receiveUser && eventDetail.receiveUser.id"
              >{{ $t("lang.invites") }}</span
            >
            <span
              class="event-accepter"
              v-show="eventDetail.receiveUser && eventDetail.receiveUser.id"
            >
              <img
                :src="
                  eventDetail.receiveUser
                    ? eventDetail.receiveUser.avatar
                    : avatarDefault
                "
                class="receiveUser-avatar"
              />
              <span class="receiveUser-username"
                >{{
                  eventDetail.receiveUser
                    ? eventDetail.receiveUser.username
                    : ""
                }}
              </span>
            </span>
          </div>
          <div class="event-content">{{ eventDetail.content }}</div>
          <div class="event-description">{{ eventDetail.description }}</div>
          <div class="event-bounty">
            <span class="bounty">{{ eventDetail.bounty }}</span
            ><span style="color: #b7b7c2">$coop</span>
          </div>
          <address class="event-address">
            <i class="el-icon-location"></i
            ><span style="color: #b7b7c2">{{ eventDetail.address }}</span>
          </address>
          <div class="event-btns">
            <!-- 申领任务 -->
            <div
              class="claim-container"
              v-show="handleShowBtn('claim', eventDetail)"
            >
              <el-button
                class="fc-event-btn greenBg-event-btn"
                @click.stop="handleClaimEvent(eventDetail)"
                >{{ $t("lang.claim") }}</el-button
              >
            </div>
            <!-- 取消申领任务 -->
            <div
              class="cancelClaim-container"
              v-show="handleShowBtn('cancelclaim', eventDetail)"
            >
              <span
                style="cursor: pointer"
                @click="handleCancelClaimEvent(eventDetail)"
                >{{ $t("lang.cancelclaim") }}</span
              >
            </div>
            <!-- 拒绝 -->
            <div
              class="deny-container"
              v-show="
                handleShowBtn('receiveUser', eventDetail) &&
                handleShowBtn('deny', eventDetail)
              "
            >
              <span
                style="cursor: pointer"
                @click="collectDenyReason(eventDetail)"
                >{{ $t("lang.notInterested") }}</span
              >
            </div>
            <!-- 接受 只有B能接受-->
            <div
              class="accept-container"
              v-show="handleShowBtn('receiveUser', eventDetail)"
            >
              <el-button
                class="fc-event-btn orangeBg-event-btn"
                v-show="handleShowBtn('accept', eventDetail)"
                @click.stop="collectAcceptContact(eventDetail)"
                >{{ $t("lang.accept") }}</el-button
              >
              <el-button
                class="fc-event-btn orangeBg-event-btn"
                v-show="handleShowBtn('deny2', eventDetail)"
                @click.stop="changeToNotInterest(eventDetail)"
                >{{ $t("lang.accept")
                }}<i style="margin-left: 10px" class="el-icon-arrow-down"></i
              ></el-button>
              <el-button
                class="fc-event-btn orangeBg-event-btn"
                v-show="handleShowBtn('accept2', eventDetail)"
                @click.stop="changeToAccept(eventDetail)"
                >{{ $t("lang.notInterested")
                }}<i style="margin-left: 10px" class="el-icon-arrow-down"></i>
              </el-button>
              <div
                class="accepte-btn-container"
                v-show="
                  eventDetail.isShowAcceptedSelect ||
                  eventDetail.isShowNotInterestSelect
                "
              >
                <el-button
                  v-show="eventDetail.isShowAcceptedSelect"
                  class="fc-event-btn orangeBg-event-btn accept-select-btn"
                  @click="collectAcceptContact(eventDetail)"
                  >{{ $t("lang.accept") }}</el-button
                >
                <el-button
                  v-show="eventDetail.isShowNotInterestSelect"
                  class="fc-event-btn orangeBg-event-btn accept-select-btn"
                  @click="collectDenyReason(eventDetail)"
                  >{{ $t("lang.notInterested") }}</el-button
                >
              </div>
            </div>
            <!-- 取消 -->
            <div
              class="cancel-container"
              v-show="handleShowBtn('cancel', eventDetail)"
            >
              <el-button
                class="fc-event-btn redBg-event-btn cancel-event-btn"
                @click="handleCancelEvent(eventDetail)"
                >{{ $t("lang.cancel") }}</el-button
              >
            </div>
            <!-- 编辑 -->
            <div
              class="edit-container"
              v-show="handleShowBtn('edit', eventDetail)"
            >
              <span style="cursor: pointer" @click="handleEdit(eventDetail)">{{
                $t("lang.edit")
              }}</span>
            </div>
            <!-- 删除 -->
            <div
              class="edit-container"
              v-show="handleShowBtn('delete', eventDetail)"
            >
              <el-button
                class="fc-event-btn redBg-event-btn confirm-event-btn"
                @click="handleDeleteEvent(eventDetail)"
                >{{ $t("lang.delete") }}</el-button
              >
            </div>
            <!-- A确认B完成 -->
            <div
              class="complete-container"
              v-show="handleShowBtn('complete', eventDetail)"
            >
              <el-button
                class="fc-event-btn greenBg-event-btn complete-event-btn"
                @click="handleCompleteEvent(eventDetail)"
                >{{ $t("lang.completed") }}</el-button
              >
            </div>
            <!-- B确认收到了bounty -->
            <div
              class="complete-container"
              v-show="handleShowBtn('received', eventDetail)"
            >
              <el-button
                class="fc-event-btn greenBg-event-btn complete-event-btn"
                @click="handleEndEvent(eventDetail)"
                >{{ $t("lang.received") }}</el-button
              >
            </div>
            <!-- 回顾，只读 -->
            <div
              class="readonly-container"
              v-show="handleShowBtn('review', eventDetail)"
            >
              <el-button
                class="fc-event-btn greyBg-event-btn confirm-event-btn"
                @click="handleReadEvent(eventDetail.id)"
                >{{ $t("lang.review") }}</el-button
              >
            </div>
          </div>
          <div
            class="contact-container"
            v-show="
              handleShowBtn('contact', eventDetail) && eventDetail.status != 0
            "
          >
            <span class="contact-type-text">{{
              $t("lang.receiverContact")
            }}</span>
            <div class="contact-item">
              <span class="contact-type">
                <img
                  class="type-icon"
                  :src="typeImageCache[eventDetail.receiverContact.contactType]"
                />
                <span class="type-name">{{
                  eventDetail.receiverContact.contactType
                }}</span>
              </span>
              <el-input
                disabled
                class="contact-input"
                v-model="eventDetail.receiverContact.contactDetail"
                placeholder="Include #Code"
              ></el-input>
            </div>
          </div>
          <div
            class="contact-container"
            v-show="handleShowBtn('contact', eventDetail)"
          >
            <span class="contact-type-text">{{
              $t("lang.postUserContact")
            }}</span>
            <div class="contact-item">
              <span class="contact-type">
                <img
                  class="type-icon"
                  :src="typeImageCache[eventDetail.postUserContact.contactType]"
                />
                <span class="type-name">{{
                  eventDetail.postUserContact.contactType
                }}</span>
              </span>
              <el-input
                disabled
                class="contact-input"
                v-model="eventDetail.postUserContact.contactDetail"
                placeholder="Include #Code"
              ></el-input>
            </div>
          </div>
          <div class="event-comment">
            <span class="comment-count"
              >{{ $t("lang.claims") }}({{
                applications ? applications.length : 0
              }})</span
            >
            <div class="comments-container">
              <div
                class="comment-item"
                v-for="application of applications"
                :key="application.id"
              >
                <img :src="application.applyUser.avatar" />
                <span class="comment-name">{{
                  application.applyUser.username
                }}</span>
                <div class="contact-item">
                  <span class="contact-type">
                    <img
                      class="type-icon"
                      :src="
                        typeImageCache[
                          application.applyUser.contact.contactType
                        ]
                      "
                    />
                    <span class="type-name">{{
                      application.applyUser.contact.contactType
                    }}</span>
                  </span>
                  <el-input
                    disabled
                    class="contact-input"
                    v-model="application.applyUser.contact.contactDetail"
                  ></el-input>
                </div>
              </div>
            </div>
          </div>
          <div class="event-comment">
            <span class="comment-count"
              >{{ $t("lang.comments") }}({{
                comments ? comments.length : 0
              }})</span
            >
            <div class="comments-container">
              <div
                class="comment-item"
                v-for="comment of comments"
                :key="comment.id"
              >
                <img :src="comment.fromUser.avatar" />
                <span class="comment-name">{{
                  comment.fromUser.username
                }}</span>
                <span class="comment-content">{{ comment.content }}</span>
                <span class="time">{{ handleTime(comment.createdAt) }}</span>
              </div>
              <div class="comment-item">
                <img :src="img" />
                <span class="comment-name">{{ userInfo.name }}</span>
                <el-input
                  class="comment-content"
                  size="mini"
                  v-model="inputComment"
                  :placeholder="$t('lang.addComment')"
                ></el-input>

                <el-button
                  class="comment-more"
                  @click="addComment(eventDetail)"
                  >{{ $t("lang.submit") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  imagesCache,
  avatarDefault,
  statusClass,
  statusNameMap_zh,
  statusNameMap_en,
} from "@data/avatar.js";
import {
  getEventDetail,
  getEventComments,
  getEventApplications,
  leaveMessage,
} from "@/api/public.js";
import timeStageDivide from "@/mixins/timeStageDivide.js";
import moduleMethodMixin from "@/mixins/moduleMethod.js";
import btnShowMixin from "@/mixins/btnShowMixins.js";

export default {
  name: "moduleDialog_wap",
  mixins: [timeStageDivide, btnShowMixin, moduleMethodMixin],
  data() {
    return {
      eventDetail: {
        postUser: {
          contact: {
            contactDetail: "",
            contactType: "",
          },
        },
        receiveUser: {
          contact: {
            contactDetail: "",
            contactType: "",
          },
        },
        receiverContact: {
          contactType: "",
          contactDetail: "",
        },
        postUserContact: {
          contactType: "",
          contactDetail: "",
        },
      },
      statusClass: statusClass,
      statusNameMap: statusNameMap_zh,
      loading: false,
      comments: [],
      inputComment: "",
      applications: [],
    };
  },
  computed: {
    ...mapGetters(["isShowModuleDialog", "moduleEventId"]),
    img() {
      if (this.userInfo && this.userInfo.avatar) {
        if (this.userInfo.avatar.length > 2) {
          return this.userInfo.avatar;
        } else {
          return imagesCache[this.userInfo.avatar];
        }
      } else {
        return avatarDefault;
      }
    },
  },
  watch: {
    moduleEventId(val) {
      if (val) {
        this.getEventModule();
      }
    },
    "$i18n.locale"(val) {
      if (val == "zh-CN") {
        this.statusNameMap = statusNameMap_zh;
      } else {
        this.statusNameMap = statusNameMap_en;
      }
    },
  },
  mounted() {
    if (this.moduleEventId) {
      this.getEventModule();
    }
  },
  methods: {
    /**
     * 初始化请求事件的信息
     */
    async getEventModule() {
      let vm = this;
      vm.loading = true;
      try {
        let res = await getEventDetail({
          eventId: this.moduleEventId,
        });
        if (res.code == 200) {
          let item = res.data;
          vm.preSetEvent(item);
          this.eventDetail = {
            id: item.id,
            status: item.status,
            date: [
              new Date(item.startTime).getTime(),
              new Date(item.endTime).getTime(),
            ],
            postUser: item.postUser,
            postUserContact: item.postUserContact,
            receiverContact: item.receiverContact,
            receiveUser: item.receiveUser,
            bounty: item.remuneration,
            content: item.topic,
            description: item.description,
            address: item.location,
          };
        } else {
          vm.$message.warning(res.message);
        }
        await this.getComments();
        await this.getClaims();
      } catch (error) {
        console.log(error);
        this.setEditEventDialog([false, null]);
      }
      vm.loading = false;
    },
    async getComments() {
      try {
        let coms = await getEventComments({
          eventId: this.moduleEventId,
          page: 1,
          pageSize: 500,
        });
        if (coms.code == 200) {
          let items = coms.data;
          this.comments = [];
          for (let item of items) {
            item.fromUser.avatar =
              item.fromUser.avatar.length > 3
                ? item.fromUser.avatar
                : imagesCache[item.fromUser.avatar]
                ? imagesCache[item.fromUser.avatar]
                : avatarDefault;
            this.comments.push({
              id: item.id,
              createdAt: item.createdAt,
              fromUser: item.fromUser,
              content: item.content,
            });
          }
        } else {
          this.comments = [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getClaims() {
      try {
        let applications = await getEventApplications({
          eventId: this.moduleEventId,
          page: 1,
          pageSize: 500,
        });
        if (applications.code == 200) {
          let items = applications.data;
          this.applications = [];
          for (let item of items) {
            item.applyUser.avatar =
              item.applyUser.avatar.length > 3
                ? item.applyUser.avatar
                : imagesCache[item.applyUser.avatar]
                ? imagesCache[item.applyUser.avatar]
                : avatarDefault;
            item.applyUser.contact = JSON.parse(item.applyUser.contact);
            this.applications.push({
              id: item.id,
              createdAt: item.createdAt,
              applyUser: item.applyUser,
            });
          }
        } else {
          this.applications = [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeModuleDialog() {
      this.setModuleDialog([false, null]);
    },
    async addComment(event) {
      if (this.inputComment) {
        try {
          let res = await leaveMessage({
            eventId: event.id,
            message: this.inputComment,
          });
          if (res && res.code == 200) {
            this.inputComment = "";
            this.$message.success(this.$t("lang.submitSuccess"));
            let coms = await getEventComments({
              eventId: event.id,
              page: 1,
              pageSize: 500,
            });
            if (coms.code == 200) {
              let items = coms.data;
              let newComment = items.filter(
                (i) => !this.comments.find((c) => c.id == i.id)
              )[0];
              if (newComment) {
                newComment.fromUser.avatar =
                  newComment.fromUser.avatar.length > 3
                    ? newComment.fromUser.avatar
                    : imagesCache[newComment.fromUser.avatar]
                    ? imagesCache[newComment.fromUser.avatar]
                    : avatarDefault;
                this.comments.push({
                  id: newComment.id,
                  createdAt: newComment.createdAt,
                  fromUser: newComment.fromUser,
                  content: newComment.content,
                });
              }
            }
          } else {
            this.$message.error(res.message);
          }
        } catch (error) {
          this.$message.error(error);
          console.log(error);
        }
      } else {
        this.$message.error(this.$t("lang.commentNull"));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";

.info-module-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 999;
  background: #00000080;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .module-dialog {
    position: absolute;
    left: 2%;
    width: 96%;
    height: 58rem;
    border-radius: 2rem;
    background-image: url("../../../assets/dialog-bg.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    .dialog-header {
      width: calc(100% - 40px);
      position: relative;
      padding: 20px;
      text-align: center;
    }
    .event-container {
      width: 100%;
      height: calc(100% - 122px);
      margin-top: 20px;
      overflow: auto;
      .comments-container {
        .comment-item {
          display: flex;
          align-items: flex-start;
          margin: 1.6rem 0px;
          img {
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 1.2rem;
            border: 1px solid #d8d8d8;
            box-sizing: border-box;
          }
          .comment-name {
            margin-left: 1rem;
            line-height: 2.4rem;
            max-width: 10rem;
            max-height: 2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .comment-content {
            flex: 1;
            margin: 0 2rem;
          }
          .comment-more {
            width: fit-content;
            flex: none;
            cursor: pointer;
            padding: 0px 1rem;
            border: 1px solid #141415;
            border-radius: 3rem;
            width: 10rem;
            text-align: center;
          }
        }
      }
    }
    .el-icon-close {
      border-radius: 12px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}
</style>
<style lang="scss">
@import "@/style/var.scss";
.module-dialog .comment-item .el-input__inner {
  border-radius: 8px;
  background-color: #fefae9;
}
.event-container .el-form-item {
  margin-bottom: 42px;
}
</style>
