<!--
@description: 头部
@author: fc
@createtime: 2021年10月21日 11:19:56
-->
<template>
  <div
    :class="isHideHeader ? 'header is-header-hide' : 'header'"
    style="background: transparent"
  >
    <div class="nav-container_wap">
      <img class="avatar userbar-right" :src="img" @click="toggleUserMenu" />
      <i
        :class="[
          'nav-menu-icon',
          isShowSearchMenu ? 'el-icon-right' : 'el-icon-search',
        ]"
        @click="toggleSearch"
      ></i>
      <transition name="el-fade-in-linear">
        <el-select
          v-show="isShowSearchMenu"
          size="mini"
          class="invite-input"
          popper-class="header-search"
          v-model="targetName"
          filterable
          remote
          placeholder="查询用户"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in userOptions"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
            <div class="select-wrap">
              <img :src="item.avatar" class="select-avatar" />
              <span class="select-username">{{ item.label }}</span>
            </div>
          </el-option>
        </el-select>
      </transition>
      <i
        :class="[
          'nav-menu-icon rightest-icon',
          isShowMoreMenu ? 'el-icon-close' : 'el-icon-s-fold',
        ]"
        @click="toggleMoreMenu"
      ></i>
    </div>

    <el-collapse-transition>
      <div class="submenu-container user-menu" v-show="isShowUserMenu">
        <a v-for="nav of usernavs" :key="nav.id" @click="handleUserNav(nav)">{{
          $t(`lang.${nav.content}`)
        }}</a>
      </div>
    </el-collapse-transition>
    <el-collapse-transition>
      <div class="submenu-container more-menu" v-show="isShowMoreMenu">
        <a
          :class="$route.name == nav.name ? 'nav nav-active' : 'nav'"
          v-for="nav of navs"
          :key="nav.id"
          @click="handleRouteChange(nav)"
          >{{ $t(`lang.${nav.content}`) }}</a
        >
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { imagesCache, avatarDefault } from "@data/avatar.js";
import { matchUsers } from "@/api/public.js";
export default {
  name: "NoticeHeader",
  props: ["isHideHeader"],
  data() {
    return {
      navs: [
        {
          id: 1,
          content: "home",
          name: "coopList_wap",
        },
        {
          id: 2,
          content: "about",
          name: "about_wap",
        },
        {
          id: 3,
          content: "lang",
          name: "lang",
        },
      ],
      usernavs: [
        {
          id: 1,
          content: "usercenter",
          name: "profile",
        },
        {
          id: 2,
          content: "logout",
          name: "logout",
        },
      ],
      userOptions: [],
      loading: false,
      isShowMoreMenu: false,
      isShowSearchMenu: false,
      isShowUserMenu: false,
      targetName: "",
      lang: "zh-CN",
    };
  },
  watch: {
    async targetName(val) {
      let user = this.userOptions.find((user) => user.value == val);
      if (user) {
        this.handleOpenProfile(user.value);
      }
      if (val) {
        this.$message("功能完善中~");
      }
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
    email() {
      if (this.userInfo && this.userInfo.email) {
        return this.userInfo.email;
      } else {
        return "email";
      }
    },
    img() {
      if (this.userInfo && this.userInfo.avatar) {
        if (this.userInfo.avatar.length > 2) {
          return this.userInfo.avatar;
        } else {
          return imagesCache[this.userInfo.avatar];
        }
      } else {
        return avatarDefault;
      }
    },
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    async remoteMethod(query) {
      let vm = this;
      if (query !== "") {
        this.loading = true;
        await matchUsers({
          keyword: query,
        })
          .then((res) => {
            if (res.code == 200) {
              vm.loading = false;
              vm.userOptions = res.data.map((item) => {
                return {
                  avatar: imagesCache[item.avatar],
                  value: item.id,
                  email: item.email,
                  label: item.username,
                };
              });
            } else {
              this.$message.warning(res.message);
            }
          })
          .catch((e) => {
            console.log(e);
            this.$message.warning(e.message);
          });
      } else {
        this.options = [];
      }
    },
    handleRouteChange(nav) {
      this.isShowMoreMenu = false;
      this.isShowSearchMenu = false;
      this.isShowUserMenu = false;
      if (nav.name == "lang") {
        this.changeLanguage();
      } else if (nav.name != this.$route.name) {
        this.$router.push({
          name: nav.name,
        });
      }
    },
    changeLanguage() {
      if (this.lang == "en-US") {
        this.lang = "zh-CN";
        this.$i18n.locale = this.lang; //关键语句
      } else {
        this.lang = "en-US";
        this.$i18n.locale = this.lang; //关键语句
      }
    },
    jumpToUserCenter() {
      if ("user_wap" != this.$route.name) {
        this.$router.push({
          name: "user_wap",
          query: {
            id: this.userInfo.id,
          },
        });
      }
    },
    userLogout() {
      this.isLogin = false;
      this.setUserInfo(null);
      if (this.$route.name != "coopList_pc") {
        this.$router.push({
          name: "coopList_pc",
        });
      }
    },
    handleUserNav(nav) {
      this.isShowMoreMenu = false;
      this.isShowSearchMenu = false;
      this.isShowUserMenu = false;
      switch (nav.id) {
        case 1:
          this.jumpToUserCenter(nav);
          break;
        case 2:
          this.userLogout();
          break;
        default:
          break;
      }
    },
    toggleMoreMenu() {
      this.isShowUserMenu = false;
      this.isShowMoreMenu = !this.isShowMoreMenu;
    },
    toggleUserMenu() {
      this.isShowMoreMenu = false;
      this.isShowUserMenu = !this.isShowUserMenu;
    },
    toggleSearch() {
      this.isShowSearchMenu = !this.isShowSearchMenu;
    },
    handleOpenProfile(id) {
      let routeUrl = this.$router.resolve({
        path: "/user_profile_wap",
        query: {
          id: id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.is-header-hide {
  opacity: 0;
}
.header {
  position: absolute;
  display: flex;
  width: 100%;
  font-size: 1.6rem;
  transition: opacity 0.3s;
  justify-content: right;
  z-index: 1;
  user-select: none;
  .nav-container_wap {
    font-weight: 400;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;
    width: fit-content;
    height: 76px;
    display: inline-flex;
    align-items: center;
    color: $color-primary;
    .nav-menu-icon {
      margin-left: 20px;
      font-size: 2rem;
    }
    .el-icon-close {
      background-color: transparent;
    }
    .rightest-icon {
      margin-right: 20px;
    }
    .avatar {
      width: 36px;
      height: 36px;
      cursor: pointer;
      border-radius: 10rem;
    }
    .invite-input {
      width: 120px;
      margin-left: 20px;
    }
  }
  .submenu-container {
    position: absolute;
    display: block;
    top: 68px;
    right: 0px;
    width: 100%;
    overflow: hidden;
    padding: 8px 0;
    background: #fff;
    box-shadow: 0 1px 2px rgb(153, 153, 153);
    border-radius: 0px;
    border: 0;
    a {
      background: #fff;
      color: #333;
      text-decoration: none;
      display: block;
      font-size: 1.4rem;
      text-align: left;
      padding: 20px;
      cursor: pointer;
      &:nth-child(2) {
        padding-top: 10px;
      }
    }
    a:hover {
      color: $color-orange;
      text-decoration: none;
      background: 0 0;
    }
  }
}
</style>

<style lang="scss">
.language-change .el-button--text {
  border: unset;
}
.header-search {
  .select-wrap {
    display: flex;
    align-items: center;
  }
  .select-avatar {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    opacity: 1;
    float: left;
  }
  .select-username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    float: left;
  }
}
</style>
