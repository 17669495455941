<!--
@description: redirectDialog
@author: fc
@createtime: 2021年11月22日 13:49:00
-->
<template>
  <div class="redirect-dialog-wrap_wap">
    <div class="redirect-dialog">
      <div class="dialog-header">
        <i class="el-icon-close" @click="closeDialog"></i>
      </div>
      <div class="redirect-content-container">
        <div class="redirect-container">
          <h2>{{ $t("lang.loginin") }}</h2>
          <p>{{ $t("lang.logininTip[0]") }}</p>
          <p>
            {{ $t("lang.logininTip[1]") }}
            <a @click="loginUnipass">{{ $t("lang.logininTip[2]") }}</a>
            {{ $t("lang.logininTip[3]") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    /**
     * 处理登录跳转
     */
    loginUnipass() {
      const UNIPASS_BASE = process.env.VUE_APP_UNIPASS_URL;
      const unipassJump = function (type, data = {}) {
        data.success_url = encodeURIComponent(window.location.href);
        const query = Object.keys(data)
          .map((key) => `${key}=${data[key]}`)
          .join("&");
        const url = `${UNIPASS_BASE}/${type}?${query}`;
        window.open(url);
        // window.location.href = url;
      };
      unipassJump("login");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/var.scss";
.redirect-dialog-wrap_wap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 999;
  background: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
  .redirect-dialog {
    position: relative;
    width: 40%;
    height: 500px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 3px;
    background-image: url("../../../assets/dialog-bg.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    .dialog-header {
      width: calc(100% - 40px);
      position: relative;
      padding: 20px;
      text-align: center;
    }
    .redirect-content-container {
      width: 100%;
      height: calc(100% - 122px);
      margin-top: 20px;
      overflow: auto;
    }
    .el-icon-close {
      position: absolute;
      right: 20px;
      top: 20px;
      border-radius: 12px;
      cursor: pointer;
    }
    .redirect-container {
      width: 60%;
      margin-left: 20%;
      font-size: 1.6rem;
      text-align: center;
      h2 {
        margin-bottom: 200px;
      }
      p {
        color: $color-text;
      }
      a {
        cursor: pointer;
        color: $color-text;
        text-decoration: underline;
      }
    }
  }
}
</style>
