import { render, staticRenderFns } from "./collectDialog_wap.vue?vue&type=template&id=7a48d8f1&scoped=true&"
import script from "./collectDialog_wap.vue?vue&type=script&lang=js&"
export * from "./collectDialog_wap.vue?vue&type=script&lang=js&"
import style0 from "./collectDialog_wap.vue?vue&type=style&index=0&id=7a48d8f1&lang=scss&scoped=true&"
import style1 from "./collectDialog_wap.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a48d8f1",
  null
  
)

export default component.exports