import { render, staticRenderFns } from "./redirectDialog_wap.vue?vue&type=template&id=acab770a&scoped=true&"
import script from "./redirectDialog_wap.vue?vue&type=script&lang=js&"
export * from "./redirectDialog_wap.vue?vue&type=script&lang=js&"
import style0 from "./redirectDialog_wap.vue?vue&type=style&index=0&id=acab770a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acab770a",
  null
  
)

export default component.exports